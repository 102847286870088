import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/articles-layout.js";
import { Note } from '../../components/mdx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`¿Por qué Serverless?`}</h2>
    <p>{`Serverless es la siguiente evolución de Computación en la nube ☁️💻☁️ (`}<em parentName="p">{`Cloud Computing`}</em>{`).`}</p>
    <h3>{`IaaS - Infraestructura como Servicio`}</h3>
    <p>{`Anteriormente la mejor alternativa que teníamos en AWS era EC2 (Elastic Cloud Computing). `}<strong parentName="p">{`EC2 es IaaS o infraestructura como servicio`}</strong>{`. Es decir se puede renter un servidor de amazon con ciertas prestaciones y sobre este instalar todo lo que necesitamos para ejecutar nuestras aplicaciones. Con todas las ventajas de escalamiento bajo demanda.`}</p>
    <p>{`La ventaja de utilizar EC2 es que podemos pagar solamente por los recursos que necesitamos y no debemos preocuparnos por el mantenimiento de hardware.`}</p>
    <h3>{`FaaS - Funciones c omo Servicio`}</h3>
    <p><strong parentName="p">{`FaaS`}</strong>{` toma esta idea aún más allá. Ahora no solamente no debemos preocuparnos por el hardware en el que se ejecutan nuestras aplicaciones, si no ahora `}<strong parentName="p">{`no nos preocupamos ni el sistema operativo o runtime`}</strong>{` que necesita para ejecutarse. Con una PaaS solamente nos preocupamos en nuestra aplicación.`}</p>
    <p>{`Otra de las enormes ventajas de funciones Lambda o serverless es que `}<strong parentName="p">{`solamente pagamos por lo que consumimos`}</strong>{`. Es decir solamente nos generá un gasto por los recursos y el tiempo en que nuestras funciones se ejecutan. No pagamos por tiempo Idle.`}</p>
    <p>{`Con FaaS, nosotros desplegamos código como funciones independientes y configuramos reglas para su ejecución.`}</p>
    <h2>{`AWS Lambda`}</h2>
    <p>{`Lambda es la plataforma FaaS de Amazon.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/faas.png",
        "alt": "AWS Lambdas "
      }}></img></p>
    <h3>{`Creando nuestra priemro función Lambda`}</h3>
    <h4>{`1: Ingregar a la consosola de AWS Lambda`}</h4>
    <p>{`Vamos a `}<a parentName="p" {...{
        "href": "https://console.aws.amazon.com/lambda/"
      }}>{`console.aws.amazon.com/lambda/`}</a>{`, iniciamos sesión de ser necesario.`}</p>
    <h4>{`2: Creamos una nueva función`}</h4>
    <p>{`Dependiendo si ya hemos creado una función anteriormente nos encontramos con pantallas distintas, Pero en cualquiera damos click al botón `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Create function`}</code>{` para crear una nueva función.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-console.png",
        "alt": "Consola Lambda"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-console-2.png",
        "alt": "Consola Lambda"
      }}></img></p>
    <h3>{`3: Creamos la función:`}</h3>
    <p>{`Seleccionamos `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Author from scratch`}</code>{`, le damos un nombre a nuestra función y yo quiero escribirla en `}<strong parentName="p">{`nodejs`}</strong>{` así que no cambiamos nada más. Y creamos la función dandole click en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Create Function`}</code>{`
`}<img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-creation-1.png",
        "alt": "Consola Lambda"
      }}></img></p>
    <p><strong parentName="p">{`Listo`}</strong>{`, hemos creado nuestra primero función.
`}<img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-creation-2.png",
        "alt": "Consola Lambda"
      }}></img></p>
    <p>{`El código de la función se puede observar en la sección `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Function Code`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`exports`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`handler`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`event`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// TODO implement`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    statusCode`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`200`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    body`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSON`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`stringify`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Hello from Lambda!'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Lo que hace esto es simplemente responder a cualquier evento con el contenido de `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`response`}</code>{`.`}</p>
    <h3>{`4: Probando nuestra función`}</h3>
    <p>{`Para probar nuestra función, podemos encontrar en la parte superior la opcion `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Test`}</code>{`. En esta configuramos el evento que es el parámetro que le podemos enviar a nuestra función.`}</p>
    <p>{`Cambiamos el objeto json por el siguiente, y luego creamos el evento de prueba.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"producto"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Computador"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"categoria"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Tecnología"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"precio"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-creation-3.png",
        "alt": "Consola Lambda"
      }}></img></p>
    <p>{`Con el evento creado lo podemos observar el evento creado se encuentra en la parte superior junto a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Test`}</code>{`.
Al ejecutar la función nos retorna el resultado en el cuadro marcado como `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Salida`}</code>{` junto con mucha información util de la función como: el tiempo de ejecución, maxima memoria utilizada y el `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Log output`}</code>{` que nos va a ser muy util para poder debugear nuestras funciones ya que es ahí en donde se muestra el `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`console.log()`}</code>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-creation-4.png",
        "alt": "Consola Lambda"
      }}></img></p>
    <p>{`Oficialmente `}<strong parentName="p">{`ya creaste tu primera función Lambda`}</strong>{`. En los siguientes pasos aprenderaz a hacerla más util.`}</p>
    <h3>{`5: (Extra) Utilizando datos del evento`}</h3>
    <p>{`Para acceder a las propiedades del evento como `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`producto`}</code>{` o `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`precio`}</code>{` que definimos en el `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`EventoPrueba`}</code>{` simplemente llamamos a sus propiedades.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`event`}</code>{` es simplemente un objecto `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`json`}</code>{` y lo podemos tranfromar como cualquier otro.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`exports`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`handler`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`event`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`Producto: `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`event`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`producto`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`Categoría: `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`event`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`categoria`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`Precio: `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`event`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`precio`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` jsonResponse `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    producto`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` event`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`producto`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    precio`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` event`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`precio`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` response `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    statusCode`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`200`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    body`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSON`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`stringify`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`jsonResponse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Guardamos los cambios y nuevamente ejecutamos la prueba.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-creation-5.png",
        "alt": "Consola Lambda"
      }}></img></p>
    <p>{`Podemos comprobar el resultado y como en la salida de la función, tanto en los logs.`}</p>
    <h3>{`6: (Extra) Configuaciones de la función lambda`}</h3>
    <p>{`En esta ultima sección hablaremos brevemente como conectar nuestra función con otros servicios de AWS, pero esta configuración no la realizaremos en esta publicación.`}</p>
    <h4>{`Sección `}<code parentName="h4" {...{
        "className": "language-text"
      }}>{`Designer`}</code></h4>
    <p><img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-creation-6.png",
        "alt": "Consola Lambda"
      }}></img></p>
    <p>{`En esta sección podemos configurar 3 cosas:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Triggers`}</strong>{`: Cuando se activa nuestra función. Puede conectarse con:`}<ul parentName="li">
          <li parentName="ul">{`LLamada aplicaciones`}</li>
          <li parentName="ul">{`Cambio en AWS S3`}</li>
          <li parentName="ul">{`Cambio en AWS DynamoDB`}</li>
          <li parentName="ul">{`Otros servcios externos`}</li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Layers`}</strong>{`: Utilizado para importar paquetes pesados a nuestra función. Mejora el rendimiendo de las funciones.`}</li>
      <li parentName="ul"><strong parentName="li">{`Destinations`}</strong>{`: Permite utilizar la salida de la función como entrada de:`}<ul parentName="li">
          <li parentName="ul">{`Otra función`}</li>
          <li parentName="ul">{`SNS (Simple Notification Service )`}</li>
          <li parentName="ul">{`SQS (Simple Queue Service)`}</li>
        </ul></li>
    </ul>
    <h4>{`Entornos de variable`}</h4>
    <p>{`La sección de Variables de Entorno se explica sólo. Aquí podemos almacenar variables que no queremos quemarlas en el código. Un excelente ejemplo de esto son las llaves de apis.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-creation-7.png",
        "alt": "Lambda - Variables de entorno"
      }}></img></p>
    <h4>{`Tags -Etiquetas`}</h4>
    <p>{`Las etiquetas facilitan la administración de proyectos más grandes. Esta información no configura nada. Es unicamente para nosotros. En las etiquetas solemos encontrar datos como el entorno que esta desplegado y tal vez el proyecto al que pertenece.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-creation-8.png",
        "alt": "Lambda - Variables de entorno"
      }}></img></p>
    <h4>{`Configuración básica`}</h4>
    <p>{`La siguiente configuración es una de las más importante ya que esta determina la capacidad computacional y el costo.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-creation-9.png",
        "alt": "Lambda - Configuración básica"
      }}></img></p>
    <p>{`La capacidad computacional en las funciones Lambda esta ligado a la cantidad de RAM. Y la cantiadad de memoria va desde 128MB hasta 3GB.`}</p>
    <p>{`El tiempo de ejecución máximo denominado como `}<em parentName="p">{`timeout`}</em>{` tiene un límite de 15 minutos.`}</p>
    <p>{`Si en álgun momento necesitamos ejecutar un método por más de 15 minutos debemos utilizar servicios como `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Step Functions`}</code>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-creation-10.png",
        "alt": "Lambda - Configuración básica"
      }}></img></p>
    <h4>{`Monitoreo`}</h4>
    <p>{`Esta sección de monitoreo es muy util porque nos avisará como se esta comportando nuestra función. Como observamos en la siguiente imagen podemos observar el número de invocaciones, la duración de las mismas entre otros datos.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/articulos/aws-lambda-hola-mundo/aws-lambda-creation-11.png",
        "alt": "Lambda - Configuración básica"
      }}></img></p>
    <hr></hr>
    <br />
    <h2>{`Felicidades 🙌🎉`}</h2>
    <p>{`¡Si llegaste hasta aquí estoy seguro que `}<strong parentName="p">{`aprendiste bastante`}</strong>{`!`}</p>
    <p>{`Si te gusto y te aportó valor el post me gustaría mucho que lo compartas o me comentes en `}<a parentName="p" {...{
        "href": "https://twitter.com/edzznR"
      }}>{`Twitter`}</a>{` cómo te parecio el post y si tienes algún tema en específico que quisieras que cubra me gustaría conocerlo.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      